import react from "react-dom";
import { Link } from "react-router-dom";
import "./Register.css";
import { useState } from "react";
import BASE_API_URL from "../../utils/constants";
import { useAuth } from "../../utils/AuthContext";
const Register = () => {
  const auth = useAuth();

  const [message, setMessage] = useState("");
  const [variant, setVariant] = useState("danger");
  const [phoneError, setPhoneError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("Female");
  const [baptism, setBaptism] = useState("");
  const [phone, setPhone] = useState("");
  const [residence, setResidence] = useState("");
  const [yos, setYos] = useState("");
  const [dob, setDob] = useState("");
  const [program, setProgram] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BASE_API_URL}/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          firstname: firstName,
          middlename: middleName,
          lastname: lastName,
          gender: gender,
          baptism: baptism,
          phone: phone,
          residence: residence,
          yos: yos,
          dob: dob,
          program: program,
          password: password,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        setMessage(data.message);
        setVariant("danger");
      } else {
        setMessage(data.message);
        setVariant("success");
      }
    } catch (err) {
      setMessage("An error occured. Please try again.");
      setVariant("danger");
    }
  };

  return (
    // <div className="register">
    <div
      className="container max-w-[1290px]   py-4 px-48 "
    
      style={{ background: "linear-gradient(60deg, #00315b 55%,#f9f9f9 45%)" }}
    >
      <div className="card my-4 mx-3">
        <div className="card-body">
          <div
            className="card-title text-center font-extrabold text-2xl md:text-3xl"
            style={{ textShadow: "2px 2px 4px rgba(0,0,0,0.3)" }}
          >
            TCMS - REGISTER
          </div>
          <hr />
          <form>
            <div class="row mb-3">
              <div class="col-sm-12">
                <div className="message">
                  {message && (
                    <div className={`alert alert-${variant}`}>{message}</div>
                  )}
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        required
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label for="floatingInput">First name</label>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        required
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                      />
                      <label for="floatingInput">Middle name</label>
                    </div>
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="email"
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    required
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <label for="floatingInput">Last name</label>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="form-floating mb-3">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        required
                        value={phone}
                        onChange={(e) => {
                          // test phonenumber with regex: 255(6|7)[0-9]{8}
                          if (!/^255(6|7)[0-9]{8}$/.test(e.target.value)) {
                            setPhoneError(true);
                          } else {
                            setPhoneError(false);
                          }
                          setPhone(e.target.value);
                        }}
                      />
                      <label for="floatingInput">Phone number</label>
                      {phoneError && (
                        <small className="text-danger">
                          Enter a valid phone number
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="form-floating mb-3">
                      <input
                        type="email"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label for="floatingInput">Email address</label>
                    </div>
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <input
                    type="date"
                    class="form-control"
                    id="floatingInput"
                    placeholder="name@example.com"
                    required
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                  <label for="floatingInput">Date of birth</label>
                </div>
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                      />
                      <label for="floatingInput">Degree program Eg. Bsc SE</label>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        required
                        value={residence}
                        onChange={(e) => setResidence(e.target.value)}
                      />
                      <label for="floatingInput">Residence</label>
                    </div>
                  </div>
                </div>
                <div class="form-floating mb-3">
                  <select
                    class="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    required
                    value={yos}
                    onChange={(e) => setYos(e.target.value)}
                  >
                    <option selected>Open this select menu</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="3">4</option>
                    <option value="3">5</option>
                  </select>
                  <label for="floatingSelect">Select year of study</label>
                </div>
                <div class="form-floating mb-3">
                  <select
                    class="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    required
                    value={baptism}
                    onChange={(e) => setBaptism(e.target.value)}
                  >
                    <option selected>Open this select menu</option>
                    <option value="1">-Baptised</option>
                    <option value="2">-Not Baptised</option>
                  </select>
                  <label for="floatingSelect">Select Baptism Status</label>
                </div>

                <div class="form-floating mb-3">
                  <input
                    type="password"
                    class="form-control"
                    id="floatingPassword"
                    placeholder="Password"
                    min={8}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <label for="floatingPassword">Password</label>
                </div>

                <legend class="col-form-label col-sm-12 px-3">Gender</legend>
                <div class="form-check px-5">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="gridRadios1"
                    name="gender"
                    value={"Male"}
                    checked={gender === "Male"}
                    onChange={() => setGender("Male")}
                  />
                  <label class="form-check-label" for="gridRadios1">
                    Male
                  </label>
                </div>
                <div class="form-check px-5">
                  <input
                    class="form-check-input"
                    type="radio"
                    id="gridRadios2"
                    name="gender"
                    value={"Female"}
                    checked={gender === "Female"}
                    onChange={() => setGender("Female")}
                  />
                  <label class="form-check-label" for="gridRadios2">
                    Female
                  </label>
                </div>
                <div className="message">
                  {message && (
                    <div className={`alert alert-${variant}`}>{message}</div>
                  )}
                </div>
                <div className="align-items-center text-center pt-3">
                  <button
                    class="px-10 py-2 md:px-36 rounded-md shadow-md"
                    type="submit"
                    disabled={phoneError}
                    style={{ backgroundColor: "#00315b", color: "#ffffff" }}
                  >
                    SIGN-UP
                  </button>
                  <legend style={{ fontSize: "20px" }}>
                    Already have an account?
                    <Link to="/login">login</Link>
                  </legend>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default Register;

{
  /* </div> */
}
{
  /* <div className="form-reg"> */
}

{
  /* <form className="r-form" onSubmit={handleSubmit}>
                    <h2>TCMS - REGISTER</h2>
                    <div className="message">
                        {message && <div className={`alert alert-${variant}`}>{message}</div>}
                    </div>
                    <div className="name">
                        <label htmlFor="First">
                            <input
                                type="text"
                                placeholder="First Name"
                                id="First"
                                required
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </label>
                        <label htmlFor="middlename">
                            <input
                                type="text"
                                placeholder="Middle Name"
                                id="middlename"
                                required
                                value={middleName}
                                onChange={(e) => setMiddleName(e.target.value)}
                            />
                        </label>
                        <label htmlFor="Last">
                            <input
                                type="text"
                                placeholder="Last Name"
                                id="Last"
                                required
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className="contact">
                        <label htmlFor="Phone">
                            <input
                                type="text"
                                placeholder="Phone Number (255...)"
                                id="phone"
                                required
                                value={phone}
                                onChange={(e) => {
                                    // test phonenumber with regex: 255(6|7)[0-9]{8}
                                    if (!(/^255(6|7)[0-9]{8}$/.test(e.target.value))){
                                        setPhoneError(true)
                                    }
                                    else{
                                        setPhoneError(false)
                                    }
                                    setPhone(e.target.value)}
                                }
                            />
                            {
                                phoneError &&
                                <small className="text-danger">Enter a valid phone number</small>
                            }
                        </label>
                        <label htmlFor="Email">
                            <input
                                type="Email"
                                placeholder="Email Address"
                                id="Email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </label>
                        <label htmlFor="dob">
                            <input
                                type="date"
                                placeholder="Date of Birth"
                                id="dob"
                                required
                                value={dob}
                                onChange={(e) => setDob(e.target.value)}
                            />
                        </label>
                    </div>
                    <div className="location">
                        <label htmlFor="programme">
                            <input
                                type="text"
                                placeholder="Programme"
                                id="programme"
                                required
                                value={program}
                                onChange={(e) => setProgram(e.target.value)}
                            />
                        </label>
                        <label htmlFor="yos">
                            <select
                                className='form-select'
                                id='yos'
                                required
                                value={yos}
                                onChange={(e) => setYos(e.target.value)}
                            >
                                <option value="">Select Year of Study</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </select>
                        </label>
                        <label htmlFor="Block">
                            <input
                                type="text"
                                placeholder="Residence"
                                id="Block"
                                required
                                value={residence}
                                onChange={(e) => setResidence(e.target.value)}
                            />
                        </label>
                        <label htmlFor="baptism">
                            <select
                                className='form-select'
                                id='baptism'
                                required
                                value={baptism}
                                onChange={(e) => setBaptism(e.target.value)}
                            >
                                <option value="">Select Baptism Status</option>
                                <option value="Baptised">Baptised</option>
                                <option value="Not Baptised">Not Baptised</option>
                            </select>
                        </label>
                    </div>
                    <div className="sign">
                        <div className="gender">
                        <label htmlFor="password">
                                <input
                                    type="password"
                                    placeholder="Password"
                                    id="password"
                                    min={8}
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </label>
                            <div className="gen">
                            <h4>Gender</h4>
                            <div className="male">
                                Male
                                <input
                                    type="radio"
                                    name="gender"
                                    value={"Male"}
                                    checked={gender === "Male"}
                                    onChange={() => setGender("Male")}
                                />
                            </div>
                            <div className="female">
                                Female
                                <input
                                    type="radio"
                                    name="gender"
                                    value={"Female"}
                                    checked={gender === "Female"}
                                    onChange={() => setGender("Female")}
                                />
                            </div>
                            </div>
                          
                           
                           
                         
                        </div>
                      

                    </div>
                    <div className="to-sign">
                            <div className="message">
                                {message && <div className={`alert alert-${variant}`}>{message}</div>}
                            </div>
                            <div>
                            <button type="submit" disabled={phoneError}>SIGN-UP</button>
                            </div>

                            <h4>Already Have an Account? <span><Link to="/login">Log-In</Link></span></h4>
                        </div>
                </form>

            </div> */
}

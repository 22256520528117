import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faContactBook,
  faPhone,
  faMapMarker,
  faEnvelope,
  faArrowLeft,
  faArrowRight,
  faBook,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { style } from "@mui/system";

const BlogFooter = () => {
  return (
    <footer className="w-full  bg-gray-700 text-white font-sans">
      <div
        className="flex justify-between  items-start gap-10 flex-wrap
        max-lg:flex-col"
      >
        <div className="flex  items-center justify-center  px-4 gap-3 mt-2">
          <a
            href="https://www.youtube.com/channel/UCPM1SzyxuTq5vf95ngqgWuA"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} size="2x" color="white" />
          </a>

          <a
            href="https://www.instagram.com/tucasa_cive/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" color="white" />
          </a>

          <a
            href="https://x.com/Tucasa_Cive?t=gJjILYd0KIwd5z-w3_7GoQ&s=09"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faXTwitter} size="2x" color="white" />
          </a>
        </div>
        <div className="flex flex-1 justify-around lg:gap-5 gap-1 flex-wrap ">
          <div>
            <h4 className="text-white text-2xl leading-normal font-medium">
              Related Links
            </h4>
            <ul style={{ textDecoration: "none" }}>
              <li className=" text-white hover:text-slate-gray">
                <a
                  href="https://www.adventist.org"
                  className="text-white"
                  style={{ textDecoration: "none" }}
                >
                  Adventist Website
                </a>
              </li>
              <li className="mt-1  hover:text-slate-gray">
                <a
                  href="https://www.stuadventist.or.tz"
                  className="text-white"
                  style={{ textDecoration: "none" }}
                >
                  STU Website
                </a>
              </li>
              <li className="mt-1 text-white-400 ">
                <a
                  href="https://www.ctfadventist.org"
                  className="text-white"
                  style={{ textDecoration: "none" }}
                >
                  CTF Website
                </a>
              </li>
              <li className="mt-1 text-white-400 hover:text-slate-gray">
                <a
                  href="https://www.gcyouthministries.org/events-and-projects/public-campus-ministries/.or.tz"
                  className="text-white"
                  style={{ textDecoration: "none" }}
                >
                  Adventist Youth PCM Website
                </a>
              </li>
              <li className="mt-1 text-white-400 hover:text-slate-gray">
                <a
                  href="https://www.udom.ac.tz"
                  className="text-white hover:text-slate-white"
                  style={{ textDecoration: "none" }}
                >
                  University of Dodoma
                </a>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-white text-2xl leading-normal font-medium">
              Get in touch
            </h4>
            <ul style={{ textDecoration: "none" }}>
              <li className="mt-1 text-white cursor-pointer hover:text-slate-gray">
                Phone(+255)682 827 536
              </li>
              <li className="mt-1  hover:text-slate-gray">
                Block 6 side A second Floor
              </li>
              <li className="mt-1 text-white-400 ">info@TucasaCive.com</li>
            </ul>
          </div>
          <div>
            <h4 className="text-white text-2xl leading-normal font-medium">
              Help
            </h4>
            <ul style={{ textDecoration: "none" }}>
              <li className="mt-1 text-white cursor-pointer hover:text-slate-gray">
                <Link to="/about-us" className="no-underline text-white">
                  About Us
                </Link>
              </li>
              <li className="mt-1  hover:text-slate-gray">Privacy Option</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex  justify-between  text-white mt-24 max-sm:flex-col  max-sm:items-center">
            <div className="flex flex-col justify-between  items-start px-2 max-md:items-center cursor-pointer">
              <p>Copyright &copy; 2024 Tucasa Cive</p>
              <p className="text-xs text-slate-400">developed by Laizer & Mayaya J </p>
            </div>
          </div>
    </footer>
  );
};

export default BlogFooter;

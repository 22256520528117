import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Music from "../../components/Music/Music";
import myYoutbube from "../../components/Video/video";
import { TypeAnimation } from "react-type-animation";
import ScrollFadeIn from "../../components/ScrollFadeIn";
import church from "./../../Images/church.jpg";

import bgPicture from "./../About/AboutAssets/image3.jpg";

const Home = () => {
  const [srollHeight, setScrollHeight] = useState(0);
  const [lineHeight, setLineHeight] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      const newScrollHeight = window.scrollY;
      setScrollHeight(newScrollHeight);

      const targetDiv = document.getElementById("targetDiv");
      if (targetDiv) {
        const targetDivTop = targetDiv.offsetTop;
        const targetDivHeight = targetDiv.offsetHeight;
        const scrollPositionRelativeToDiv = newScrollHeight - targetDivTop;

        const speedFactor = 1;

        const visibleHeight = Math.max(
          targetDivHeight - scrollPositionRelativeToDiv,
          0
        );

        const lineVisibleHeight = Math.min(
          visibleHeight,
          targetDivHeight,
          Math.max(scrollPositionRelativeToDiv, 0)
        );
        setLineHeight(lineVisibleHeight);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="main max-w-[1240px] mx-auto">
        <div className="section dashboard">
          <div className="h-64 relative">
            <img
              src={bgPicture}
              alt="Tucasa Cive"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-bgBlue opacity-70"></div>
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <div className="text-white md:text-5xl text-3xl font-extrabold">
                WELCOME{" "}
              </div>
              <div className="text-white md:text-4xl text-xl font-serif py-1  font-bold text-shadow-md">
                <TypeAnimation
                  sequence={[
                    "TUCASA CIVE",
                    4000,
                    "UDOM WEST SOCIAL SDA",
                    3000,
                    "UNIVERSITY OF DODOMA",
                    2000,
                  ]}
                  wrapper="span"
                  speed={50}
                  repeat={Infinity}
                />
              </div>
              <br />
              <p className="text-center text-white md:text-2xl text-xl px-1 font-extrabold font-sans ">
                Unleashing the Potential of Student Unity
              </p>
            </div>
          </div>

          <div className="row mx-3 my-2">
            <h4 className="w-full text-center py-2 text-2xl sm:text-xl md:text-3xl font-sans font-extrabold">
              We are Loved, Valued and Blessed
            </h4>
            <div
              style={{
                position: "fixed",
                left: "10px",
                top: "0",
                height: `${lineHeight}px`,
                width: "1px",
                background: "#ccc",
              }}
            ></div>
            <div
              className="w-full  grid md:grid-cols-3 grid-cols-1 "
              id="targetDiv"
            >
              <div className="px-2 " style={{ marginLeft: "10px" }}>
                <ScrollFadeIn>
                  <h4 className="w-full text-left text-blue-800 md:text-2xl font-sans">
                    Loved
                  </h4>

                  <p className="py-2 font-sans">
                    Here at Tucasa,we cherish the biblical principle of Love as
                    depicted in the bible, its more than just emotion,its
                    selfless devotion that embodies compassion,forgiveness and
                    care for others,Rooted in God's love,it guides us unites us
                    to show kindness and empathy to one another and brings Hope.
                  </p>
                </ScrollFadeIn>
              </div>
              <div className="px-2">
                <ScrollFadeIn>
                  <h4 className="w-full text-left text-blue-800 md:text-2xl font-sans">
                    Valued
                  </h4>

                  <p className="py-2 font-sans">
                    Here we emphasize the value of each individual as taught in
                    the Bible to all christian,Every person is inherently
                    valuable created in the image of God.we believe in
                    respecting and honoring the worth of every individual,
                    regardless of background or circumstance.
                  </p>
                </ScrollFadeIn>
              </div>
              <div className="px-2">
                <ScrollFadeIn>
                  <h4 className="w-full text-left text-blue-800 md:text-2xl font-sans">
                    Blessed
                  </h4>

                  <p className=" py-2 font-sans">
                    Here at Tucasa, To be blessed is to receive God's favor and
                    goodness in our lives. Its experiencing joy,peace and
                    fulfillment through our connection with God.As we embrace
                    His teachings,we find ourselves surrounded by His presence
                    and guidance,enriching every aspects of our journey in
                    faith.
                    <br />
                  </p>
                </ScrollFadeIn>
              </div>
            </div>

            <div class="inline-flex items-center justify-center w-full">
              <hr class="w-64 h-1 my-8 bg-gray-600 border-0 rounded dark:bg-gray-700" />
              <div class="absolute px-4 -translate-x-1/2 bg-white left-1/2 dark:bg-gray-900">
                <svg
                  class="w-4 h-4 text-gray-700 dark:text-gray-300"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 14"
                >
                  <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
                </svg>
              </div>
            </div>
          </div>

          <div className="w-full px-4 bg-gray-300 grid md:grid-cols-2 grid-cols-1">
            <div className="my-4">
              <h4 className="w-full py-2">ABOUT US</h4>
              <p className="md:text-xl sm:text-sm px-2 font-sans">
                "We seeks to create a welcoming environment where individuals
                can deepen their relationship with God and live out their faith
                with compassion and integrity,guided by the principles of
                love,grace, and biblical truth"
              </p>
              <div className="w-full">
                <button className="px-4 py-2 bg-primary-color rounded-full cursor-pointer shadow  ">
                  <Link
                    to="/about-us"
                    className="text-white no-underline font-sans"
                  >
                    MORE <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                </button>
              </div>
            </div>
            <div className="flex justify-center">
              <img
                src={church}
                alt=""
                className="md:p-4 p-3 my-4 shadow-md rounded-md"
              />
            </div>
          </div>

          <div className="w-full mx-auto bg-blue-950">
            <h4 className="pt-16 md:text-5xl text-3xl text-white sm:text-2xl text-center font-sans">
              GET INVOLVED
            </h4>
            <h4 className="text-center text-white md:text-xl text-sm font-sans">
              BIBLE STUDY, PRAYER ,SHARING
            </h4>
            <div className="w-full text-center">
              <Link to="/blogs">
                <button className="px-8  py-2 my-8 bg-blue-900 rounded-full cursor-pointer  text-white font-sans">
                  EXPLORE <FontAwesomeIcon icon={faArrowRight} />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="w-full  bg-gray-700 text-white max-w-[1240px] mx-auto font-sans">
          <div
            className="flex justify-between  items-start gap-10 flex-wrap
        max-lg:flex-col"
          >
            <div className="flex  items-center justify-center gap-3 mt-2 px-2">
              <a
                href="https://www.youtube.com/channel/UCPM1SzyxuTq5vf95ngqgWuA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} size="2x" color="white" />
              </a>

              <a
                href="https://www.instagram.com/tucasa_cive/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" color="white" />
              </a>

              <a
                href="https://x.com/Tucasa_Cive?t=gJjILYd0KIwd5z-w3_7GoQ&s=09"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faXTwitter} size="2x" color="white" />
              </a>
            </div>
            <div className="flex flex-1 justify-around lg:gap-5 gap-2 flex-wrap ">
              <div>
                <h4 className="text-white text-2xl leading-normal font-medium">
                  Related Links
                </h4>
                <ul style={{ textDecoration: "none" }}>
                  <li className="mt-1 text-white hover:text-slate-gray">
                    <a
                      href="https://www.adventist.org"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      Adventist Website
                    </a>
                  </li>
                  <li className="mt-1  hover:text-slate-gray">
                    <a
                      href="https://www.stuadventist.or.tz"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      STU Website
                    </a>
                  </li>
                  <li className="mt-1 text-white-400 ">
                    <a
                      href="https://www.ctfadventist.org"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      CTF Website
                    </a>
                  </li>
                  <li className="mt-1 text-white-400 hover:text-slate-gray">
                    <a
                      href="https://www.gcyouthministries.org/events-and-projects/public-campus-ministries/.or.tz"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      Adventist Youth PCM Website
                    </a>
                  </li>
                  <li className="mt-1 text-white-400 hover:text-slate-gray">
                    <a
                      href="https://www.udom.ac.tz"
                      className="text-white hover:text-slate-white"
                      style={{ textDecoration: "none" }}
                    >
                      University of Dodoma
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h4 className="text-white text-2xl leading-normal font-medium">
                  Get in touch
                </h4>
                <ul style={{ textDecoration: "none" }}>
                  <li className="mt-1 text-white cursor-pointer hover:text-slate-gray">
                    Phone(+255)682 827 536
                  </li>
                  <li className="mt-1  hover:text-slate-gray">
                    Block 6 side A second Floor
                  </li>
                  <li className="mt-1 text-white-400 ">info@TucasaCive.com</li>
                </ul>
              </div>
              <div>
                <h4 className="text-white text-2xl leading-normal font-medium">
                  Help
                </h4>
                <ul style={{ textDecoration: "none" }}>
                  <li className="mt-1 text-white cursor-pointer hover:text-slate-gray">
                    About us
                  </li>
                  <li className="mt-1  hover:text-slate-gray">
                    Privacy Option
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="flex  justify-between  text-white mt-24 max-sm:flex-col  max-sm:items-center">
            <div className="flex flex-col justify-between  items-start px-2 max-md:items-center cursor-pointer">
              <p>Copyright &copy; 2024 Tucasa Cive</p>
              <p className="text-xs text-slate-400">developed by Laizer & Mayaya J </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;

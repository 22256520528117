import React from 'react'

const BlogHeader = () => {
  return (
    <div>
        <h2 className='w-full text-center sm:text-xl md:text-3xl font-sans font-extraBold py-5 px-2'>Unleashing the Potential of Student Unity</h2>
    </div>
  )
}

export default BlogHeader
import react from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";


import chaplain from './Leader/bunga.jpg';

import Vice from "./Leader/rachel.jpg";
import treasure from "./Leader/costerr.jpg";
import secretary from "./Leader/ngusa.jpg";
import inspector from "./Leader/fidelis.jpg";
import deputy from "./Leader/tedd.jpg";
import health from "./Leader/amosi.jpg";
import education from "./Leader/shija.jpg";
import spiritual from "./Leader/mataaluma.jpg";
import secCalvary from "./Leader/love.jpg";
import religion from "./Leader/joshua.jpg";
import projector from "./Leader/mtende.jpg";
import viceprojector from "./Leader/majaliwa.jpg";
import possibility from "./Leader/vestuss.jpg";
import chair from "./Leader/chescooo.jpg";
import coord from "./Leader/james.jpg";
import spiri from "./Leader/grac.jpg";
import deputyTre from "./Leader/esther.jpg";
import coordinator from "./Leader/marie.jpg";
import communication from "./Leader/Enock.jpg";
import Depcommunication from "./Leader/amson.jpg";

import elderElisante from "./Leader/elisante.jpg";

const Leader = () => {
  const LeadersData = [
    {
      id: "1",
      image: chair,
      position: "Chairperson at TucasaCive",
      name: "Chesco A Mwangoka",
      course: "Bach. in Computer Engineering",
      YearOfstudy: "Third Year",
    },
    {
      id: "2",
      image: Vice,
      position: "Deputy Chairperson at TucasaCive",
      name: "Rachel Raphael",
      course: "Bach. in Multimedia Technology and Animation",
      YearOfstudy: "Third Year",
    },
    {
      id: "3",
      image: secretary,
      position: " Executive Secretary at Tucasa Cive ",
      name: "Samwel Ngusa",
      course: "Bachelor In Software Engineering",
      YearOfstudy: "Second Year",
    },
    {
      id: "4",
      image: deputy,
      name: "Theresia Meela",
      position: "Deputy Secretary at Tucasa Cive",
      course: "Bachelor In Digital Content & BroadCasting Engineering",
      YearOfstudy: "Third Year",
    },
    {
      id: "5",
      image: treasure,
      position: "Treasurer at Tucasa Cive",
      name: "Costantine Boniphace",
      course: "Bachelor in Computer Engineering",
      YearOfstudy: "Third Year",
    },
    {
      id: "6",
      image: deputyTre,
      position: "Deputy Treasurer at Tucasa Cive",
      name: "Esther Manenge",
      course: "Bachelor in Business Information System",
      YearOfstudy: "Second Year",
    },
    {
      id: "7",
      image: spiri,
      position: "Spiritual Leader ",
      name: "Grace Samwel",
      course: "Bachelor In Cyber Security In Digital Forensic Engineering",
      YearOfstudy: "Second Year",
    },
    {
      id: "8",
      image: spiritual,
      position: "Deputy Spiritual Leader ",
      name: "Tumaini Mataaluma",
      course: "Bach. in Multimedia Technology and Animation",
      YearOfstudy: "Second Year",
    },
    {
      id: "9",
      image: inspector,
      position: "Internal Orditor",
      name: "Fidelis Kagashe",
      course: "Bachelor In Computer Engineering",
      YearOfstudy: "Third Year",
    },
    {
      id: "10",
      image: secCalvary,
      position: "Secretary at Calvary Choir",
      name: "Loveness Zabron",
      course: "Bachelor In Business Information Sysyem",
      YearOfstudy: "Second Year",
    },
    {
      id: "11",
      image: possibility,
      position: "Posibility Ministries",
      name: "Vestus Vedasto ",
      course: "Bachelor in Multimedia Technology and Animation",
      YearOfstudy: "Third Year",
    },
    {
      id: "12",
      image: health,
      position: "Medical Missionary",
      name: "Amos Manda",
      course: "Bach. in Health Information System",
      YearOfstudy: "Second Year",
    },
    {
      id: "13",
      image: projector,
      position: "  Project Management Director",
      name: "Evance Mtende",
      course: "Bachelor In Digital Content & BroadCasting Engineering",
      YearOfstudy: "Third Year",
    },
    {
      id: "14",
      image: viceprojector,
      position: " Deputy Project Management Director",
      name: "Majaliwa Pius ",
      course: "Bachelor In Information System",
      YearOfstudy: "Third Year",
    },
    {
      id: "15",
      image: religion,
      position: "Religion Freedom",
      name: "Joshua Masawe ",
      course: "Bachelor In Digital Content & BroadCasting Engineering",
      YearOfstudy: "Third Year",
    },
    {
      id: "16",
      image: coord,
      position: "Programme Coordinator",
      name: "James Nyakwala",
      course: "Bachelor in Multimedia Technology and Animation",
      YearOfstudy: "Second Year",
    },
    {
      id: "17",
      image: coordinator,
      position: "Programme Coordinator",
      name: "Mary Mwema",
      course: "Bachelor In Telecommunication Engineering",
      YearOfstudy: "Second Year",
    },
    {
      id: "18",
      image: Depcommunication,
      position: "Communication Leader",
      name: "Amson Samson",
      course: "Diploma In Cyber Security and Digital Forensics",
      YearOfstudy: "Second Year",
    },
    {
      id: "19",
      image: communication,
      position: "Deputy Communication Leader",
      name: "Enock Enock",
      course: "Bachelor In Computer Science",
      YearOfstudy: "Second Year",
    },
    {
      id: "20",
      image: education,
      position: "Education Leaderr",
      name: "Shija Ndipo",
      course: "Bachelor In Information System",
      YearOfstudy: "Third Year",
    },
  ];
  return (
    <div className="container-fluid">
      <div class="my-3">
        <h2 style={{ textDecoration: "overline" }}>2023-2024</h2>
      </div>
      <div>
        <h4>Chaplain</h4>
         <div className="mx-auto flex items-center flex-col justify-center p-4 border">
         <img src={chaplain} alt="chaplain" className="w-1/2 h-70 md:h-64 md:w-1/4  p-2 shadow-lg rounded-md hover:scale-105 duration-300 "  />
         <div className="px-2 font-sans font-extrabold text-center my-2 text-xl">
          Bunga Edward Dettu
         </div>
         </div>
       
      </div>
      <h4 className="w-full md:text-3xl text-2xl pl-5 ">Leaders</h4>
      <div className="grid md:grid-cols-5 gap-2.5 sm:grid-cols-1 px-5 py-8">
        {LeadersData.map((leader) => {
          return (
            <div className="w-full shadow-2xl flex flex-col p-4 my-5 rounded-lg hover:scale-105 duration-300 ">
              <img
                src={leader.image}
                className="w-[120px] h-[120px]  mx-auto mt-[-5rem] bg-white border shadow rounded-full object-fill"
              />
              <h2 className="text-xl font-bold text-center py-8 font-serif">
                {leader.name}
              </h2>
              <p className="py-1 border-b mx-4 font-serif ">
                {leader.position}
              </p>
              <p className="py-1 border-b mx-4 font-serif ">{leader.course}</p>
              <p className="py-1 border-b mx-4  font-serif">
                {leader.YearOfstudy}
              </p>
            </div>
          );
        })}
      </div>

      <div className="w-full  bg-gray-700   text-white">
        <div
          className="flex justify-between  items-start gap-3 flex-wrap
        max-lg:flex-col"
        >
          <div className="flex  items-center justify-center gap-5 mt-2">
            <a
              href="https://www.youtube.com/channel/UCPM1SzyxuTq5vf95ngqgWuA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} size="2x" color="white" />
            </a>

            <a
              href="https://www.instagram.com/tucasa_cive/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" color="white" />
            </a>

            <a
              href="https://x.com/Tucasa_Cive?t=gJjILYd0KIwd5z-w3_7GoQ&s=09"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faXTwitter} size="2x" color="white" />
            </a>
          </div>
          <div className="flex flex-1 justify-around lg:gap-5 gap-2 flex-wrap ">
            <div>
              <h4 className="text-white text-2xl leading-normal font-medium">
                Related Links
              </h4>
              <ul style={{ textDecoration: "none" }}>
                <li className="mt-1 text-white hover:text-slate-gray">
                  <a
                    href="https://www.adventist.org"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Adventist Website
                  </a>
                </li>
                <li className="mt-1  hover:text-slate-gray">
                  <a
                    href="https://www.stuadventist.or.tz"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    STU Website
                  </a>
                </li>
                <li className="mt-1 text-white-400 ">
                  <a
                    href="https://www.ctfadventist.org"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    CTF Website
                  </a>
                </li>
                <li className="mt-1 text-white-400 hover:text-slate-gray">
                  <a
                    href="https://www.gcyouthministries.org/events-and-projects/public-campus-ministries/.or.tz"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Adventist Youth PCM Website
                  </a>
                </li>
                <li className="mt-1 text-white-400 hover:text-slate-gray">
                  <a
                    href="https://www.udom.ac.tz"
                    className="text-white hover:text-slate-white"
                    style={{ textDecoration: "none" }}
                  >
                    University of Dodoma
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="text-white text-2xl leading-normal font-medium">
                Get in touch
              </h4>
              <ul style={{ textDecoration: "none" }}>
                <li className="mt-1 text-white cursor-pointer hover:text-slate-gray">
                  Phone(+255)682 827 536
                </li>
                <li className="mt-1  hover:text-slate-gray">
                  Block 6 side A second Floor
                </li>
                <li className="mt-1 text-white-400 ">info@TucasaCive.com</li>
              </ul>
            </div>
            <div>
              <h4 className="text-white text-2xl leading-normal font-medium">
                Help
              </h4>
              <ul style={{ textDecoration: "none" }}>
                <li className="mt-1 text-white cursor-pointer hover:text-slate-gray">
                  About us
                </li>
                <li className="mt-1  hover:text-slate-gray">Privacy Option</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex  justify-between  text-white mt-24 max-sm:flex-col  max-sm:items-center">
            <div className="flex flex-col justify-between  items-start px-2 max-md:items-center cursor-pointer">
              <p>Copyright &copy; 2024 Tucasa Cive</p>
              <p className="text-xs text-slate-400">developed by Laizer & Mayaya J </p>
            </div>
          </div>
      </div>
    </div>
  );
};
export default Leader;

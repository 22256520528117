import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Data } from "../data";
import { createBrowserHistory } from "history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleLeft,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import BASE_API_URL from "../../utils/constants";
//import "./Single.css"

function Single() {
  const [isExpanded, setIsExpanded] = useState(false);
  const history = createBrowserHistory();

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const { newsId } = useParams();
  const [announcement, setAnnouncement] = useState({});

  const fetchAnnouncement = async () => {
    try {
      const resp = await fetch(`${BASE_API_URL}/announcements/public/${newsId}`)
      const data = await resp.json()
      setAnnouncement(data.announcement)
      console.log(data.announcement);
    }
    catch (error) {
      console.log(error)
    }
    finally {
      console.log("I am done")
    }
  }

  useEffect(() => {
    fetchAnnouncement()
  }, [])

  return (
    <div className="w-full px-4">
      <div className="w-full text-center md:text-3xl text-2xl py-2">
        Announcement
      </div>

      <h4 className="md:texl-2xl texl-xl font-bold font-serif">{announcement.title}</h4>

      <div className="w-full font-extrabold py-2">
        <FontAwesomeIcon icon={faClock} size="1x" color="#00315B" />
        <span>{announcement.created_at}</span>
      </div>
      {
        announcement.image ? (
          <div className="w-full h-80 md:h-full md:max-w-[768px] px-1 mx-auto">
            <img src={`${BASE_API_URL}${announcement.image}`} alt="" className="w-full h-full rounded-md" />
          </div>
        ) : ""
      }

      <div
        onClick={toggleReadMore}
        className="md:text-xl text-sm font-serif px-2 py-2"
      >
        <p>
          {isExpanded ?
            announcement.description :
            `${announcement.description}`}
        </p>
      </div>
      <div className="w-full flex justify-end py-4 px-5">
        <Link to="/news">
          {" "}
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            size="3x"
            color="#00315b"
          />
        </Link>
      </div>
    </div>
  )
}
export default Single;

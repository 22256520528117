import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft,faClock } from '@fortawesome/free-solid-svg-icons';

const Blog = () => {
  return (
    <div className='w-full font-serif'>
        <h4 className='w-full md:text-2xl tex-xl text-center'>Lorem ipsum </h4>
            <div className='px-4'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Temporibus ad quidem quo est suscipit fugit at quod ab nam cumque perspiciatis, aliquid corrupti omnis nesciunt. Incidunt earum quas asperiores error.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae nihil inventore maxime voluptatibus praesentium blanditiis dolore commodi reiciendis vel quisquam optio omnis, at sit aliquam aliquid voluptas illum adipisci culpa!
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit voluptatem temporibus earum autem totam nulla laboriosam dolores iure quam. Laudantium error quis possimus animi perspiciatis culpa eveniet earum odio atque?
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident non laboriosam ducimus consequuntur, commodi soluta libero dolores neque blanditiis harum fugit obcaecati sint magnam quis totam esse. Quo, cupiditate repellat.
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Fugit nobis in provident architecto tenetur ad alias. Molestias repudiandae at nisi alias nobis libero atque hic eligendi! Voluptatum minima quam repellendus.
            </div>
            <div className="w-full flex justify-end px-5 py-4">
     <Link to="/blogs" >   <FontAwesomeIcon icon={faArrowAltCircleLeft} size="3x" color="#00315b" /></Link>
     </div>
    </div>
  )
}

export default Blog
import React, { useEffect, useState } from "react";
import { Data } from "../data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { useParams, Link, Route } from "react-router-dom";
import BASE_API_URL from "../../utils/constants";

import {
  faInstagram,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";

const News = () => {
  const { newsId } = useParams();

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;

  const totalPages = Math.ceil(Data.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleItems = Data.slice(startIndex, endIndex);

  const [announcements, setAnnouncements] = useState([]);

  const fetchAnnouncements = async () => {
    try {
      const resp = await fetch(`${BASE_API_URL}/announcements/public`)
      const data = await resp.json()
      setAnnouncements(data.announcements)
      console.log(data.announcements);
    }
    catch (error) {
      console.log(error)
    }
    finally {
      console.log("I am done")
    }
  }

  useEffect(() => {
    fetchAnnouncements()
  }, [])

  return (
    <div className="w-full max-w-[1240px] mx-auto">
      <div className="w-full italic font-extrabold font-sans px-4 text-center py-2">
        LATEST ANNOUNCEMENT
      </div>

      <div className="py-2">
        {
          // announcements
          announcements.map((update) => {
            return (
              <div
                className="border border-b grid md:grid-cols-2 grid-cols-1 md:border-b-2"
                key={update.id}
              >
                <div className="py-2 md:border-r-3 space-x-1 border-none">
                  {
                    update.image ? (
                      <div className="h-96  w-full px-4  rounded-md">
                        <Link to={`/news/${update.id}`} className="underline-none">
                          <img
                            src={`${BASE_API_URL}${update.image}`}
                            className="w-full h-full object-fill my-4 rounded-md"
                            alt="No post image for this announcement"
                          />

                        </Link>
                      </div>
                    ) : ""
                  }
                </div>
                <div className="px-4">
                  <div className="">
                    <Link
                      to={`/news/${update.id}`}
                      className="no-underline text-black"
                    >
                      <div className="md:text-xl text-sm font-serif px-2 py-4 ">
                        {update.tittle}
                      </div>
                      <div className="py-2 px-3 ">
                        <FontAwesomeIcon
                          icon={faClock}
                          size="lg"
                          color="#00315B"
                        />
                        {update.created_at}
                      </div>
                    </Link>
                  </div>
                </div>
                <div></div>
              </div>
            );
          })
        }
      </div>

      <div className="w-full flex justify-center py-4">
        <div className="flex">
          <button
            className="flex items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={prevPage}
            disabled={currentPage === 1}
          >
            <svg
              className="w-3.5 h-3.5 me-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 5H1m0 0 4 4M1 5l4-4"
              />
            </svg>
            Previous
          </button>
          <button
            className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            onClick={nextPage}
            disabled={currentPage === totalPages}
          >
            Next
            <svg
              className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 5h12m0 0L9 1m4 4L9 9"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="w-full  bg-gray-700 text-white ">
        <div
          className="flex justify-between  items-start  gap-3 flex-wrap
        max-lg:flex-col"
        >
          <div className="flex  items-center justify-center gap-3 mt-2 px-2">
            <a
              href="https://www.youtube.com/channel/UCPM1SzyxuTq5vf95ngqgWuA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} size="2x" color="white" />
            </a>

            <a
              href="https://www.instagram.com/tucasa_cive/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" color="white" />
            </a>

            <a
              href="https://x.com/Tucasa_Cive?t=gJjILYd0KIwd5z-w3_7GoQ&s=09"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faXTwitter} size="2x" color="white" />
            </a>
          </div>
          <div className="flex flex-1 justify-around lg:gap-5 gap-2 flex-wrap ">
            <div>
              <h4 className="text-white text-2xl leading-normal font-medium">
                Related Links
              </h4>
              <ul style={{ textDecoration: "none" }}>
                <li className="mt-1 text-white hover:text-slate-gray">
                  <a
                    href="https://www.adventist.org"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Adventist Website
                  </a>
                </li>
                <li className="mt-1  hover:text-slate-gray">
                  <a
                    href="https://www.stuadventist.or.tz"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    STU Website
                  </a>
                </li>
                <li className="mt-1 text-white-400 ">
                  <a
                    href="https://www.ctfadventist.org"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    CTF Website
                  </a>
                </li>
                <li className="mt-1 text-white-400 hover:text-slate-gray">
                  <a
                    href="https://www.gcyouthministries.org/events-and-projects/public-campus-ministries/.or.tz"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Adventist Youth PCM Website
                  </a>
                </li>
                <li className="mt-1 text-white-400 hover:text-slate-gray">
                  <a
                    href="https://www.udom.ac.tz"
                    className="text-white hover:text-slate-white"
                    style={{ textDecoration: "none" }}
                  >
                    University of Dodoma
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="text-white text-2xl leading-normal font-medium">
                Get in touch
              </h4>
              <ul style={{ textDecoration: "none" }}>
                <li className="mt-1 text-white cursor-pointer hover:text-slate-gray">
                  Phone(+255)682 827 536
                </li>
                <li className="mt-1  hover:text-slate-gray">
                  Block 6 side A second Floor
                </li>
                <li className="mt-1 text-white-400 ">info@TucasaCive.com</li>
              </ul>
            </div>
            <div>
              <h4 className="text-white text-2xl leading-normal font-medium">
                Help
              </h4>
              <ul style={{ textDecoration: "none" }}>
                <li className="mt-2 text-white cursor-pointer hover:text-slate-gray">
                  About us
                </li>
                <li className="mt-2  hover:text-slate-gray">Privacy Option</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex  justify-between  text-white mt-24 max-sm:flex-col  max-sm:items-center">
          <div className="flex flex-col justify-between  items-start px-2 max-md:items-center cursor-pointer">
            <p>Copyright &copy; 2024 Tucasa Cive</p>
            <p className="text-xs text-slate-400">developed by Laizer & Mayaya J </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import "./Navbar.css";

import "./Navbar.css";
import { useAuth } from '../../utils/AuthContext';


function Navbar() {
    const auth = useAuth()
    const [isMenuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false)
    }

    const handleNavLinkclick = () => {
        closeMenu()
    }
    return (
        <div className=''>


            <nav class='py-auto' className={`navbar ${isMenuOpen ? 'open' : ''}`}>
                <div className="w-full py-2 flex justify-around    text-black">
                <div className="text-xl">
            <span className='font-sans font-extrabold'>TUCASA CIVE</span>
           
         </div>
                    <div className={`navbar-items ${isMenuOpen ? 'show' : ''}`}  >

                        <Link to="/" className='w-full py-3  border-b ' onClick={handleNavLinkclick} >
                            Home
                        </Link>
                        <Link to="/about-us" className='w-full py-3 px-2 border-b' onClick={handleNavLinkclick} >
                        About
                        </Link>
                        <Link to="/leader" className='w-full py-3 border-b' onClick={handleNavLinkclick} >
                         Leadership
                        </Link>
                        <Link to="/news" className='w-full py-3 border-b' onClick={handleNavLinkclick}>
                            Announcements
                        </Link>
                        <Link to="/blogs" className='w-full py-3 border-b' onClick={handleNavLinkclick} >
                         Blogs
                        </Link>
                       
                        {
                            auth.isLoggedIn() ? (
                                <Link to="/dashboard/index" className='w-full py-3 border-b' onClick={handleNavLinkclick}>
                                    Dashboard
                                </Link>
                            ) : (
                                <Link to="/login" className='w-full py-3 border-b' onClick={handleNavLinkclick}>
                                    Login
                                </Link>
                            )
                        }
                    </div>

                    <div className="menu-button" onClick={toggleMenu}>
                        <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
                    </div>
                </div>
            </nav>


        </div>
    )


} export default Navbar
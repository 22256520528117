import React from "react";
// import "./About.css"
import { useState, useEffect, useRef } from "react";
import prayer from "./../../Images/sdalogo2.jpg";
import ScrollFadeIn from "../../components/ScrollFadeIn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faContactBook,
  faPhone,
  faMapMarker,
  faEnvelope,
  faArrowLeft,
  faArrowRight,
  faBook,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { useSpring, animated } from "react-spring";

import image1 from "./AboutAssets/image1.jpg";
import image2 from "./AboutAssets/imag2.jpg";
import image3 from "./AboutAssets/image3.jpg";
// import image4 from './AboutAssets/image4.jpg'
import image5 from "./AboutAssets/image5.jpg";
import image11 from "./AboutAssets/image11.jpg";
import image12 from "./AboutAssets/image12.jpg";
// import image13 from './AboutAssets/image13.jpg'
import image14 from "./AboutAssets/image14.jpg";
import image15 from "./AboutAssets/image15.jpg";
import image16 from "./AboutAssets/image16.jpg";
import image17 from "./AboutAssets/image17.jpg";

import choir from "./AboutAssets/choirs.jpg";

import video from "./AboutAssets/VID-20240415-WA0001.mp4";

const images = [
  image1,
  image2,
  image3,
  // image4,
  image5,
  image11,
  image12,
  // image13,
  image14,
  image15,
  image16,
  image17,
];

const About = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const intervalRef = useRef(null);
  const slideshowDuration = 3000;

  const [startAnimation, setStartAnimation] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    startSlideshow();
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          setStartAnimation(true);
          observer.unobserve(entry.target);
        }
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      clearInterval(intervalRef.current);

      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const { number } = useSpring({
    number: startAnimation ? 80 : 1,
    from: { number: 1 },
    config: { duration: 3000 },
  });

  const startSlideshow = () => {
    intervalRef.current = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === images.length - 1 ? 0 : prevSlide + 1
      );
    }, slideshowDuration);
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
    clearInterval(intervalRef.current);
    startSlideshow();
  };

  const nextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  return (
    <div className="w-full ">
      <div className="max-w-[1040px] mx-auto">
        <div className=" max-w-[1000px] mx-auto">
          <div className="relative w-full" data-carousel="slide">
            <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`duration-700 ease-in-out ${
                    index === currentSlide ? "block" : "hidden"
                  }`}
                  data-carousel-item
                >
                  <img
                    src={image}
                    className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                    alt={`Slide ${index + 1}`}
                  />
                </div>
              ))}
            </div>

            <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
              {images.map((_, index) => (
                <button
                  key={index}
                  type="button"
                  className={`w-3 h-3 rounded-full ${
                    index === currentSlide ? "bg-black" : "bg-white"
                  }`}
                  aria-current={index === currentSlide}
                  aria-label={`Slide ${index + 1}`}
                  onClick={() => goToSlide(index)}
                />
              ))}
            </div>

            <button
              type="button"
              className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
              data-carousel-prev
              onClick={prevSlide}
            >
              <svg
                class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
              <span class="sr-only">Previous</span>
            </button>

            <button
              type="button"
              className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
              data-carousel-next
              onClick={nextSlide}
            >
              <svg
                class="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span class="sr-only">Next</span>
            </button>
          </div>
        </div>

        <div className="w-full">
          <h4 className="w-full text-center py-3 md:text-3xl text-xl  font-sans font-bold">
            Welcome To Tucasa Cive
          </h4>
          <div className="grid md:grid-cols-2 grid-cols-1 ">
            <div className=" py-4 px-5  bg-gray-100 shadow-md font-sans">
              <ScrollFadeIn>
                At Tucasa Cive, we are a vibrant community of believers
                dedicated to living out the teachings of Jesus Christ. Our
                Adventist faith shapes who we are, influencing our worship, our
                relationships, and our service to others.
              </ScrollFadeIn>
            </div>
            <div>
              <video class="w-[96] h-auto max-w-full px-4 mx-auto" controls>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="w-full grid md:grid-cols-4 grid-cols-1 gap-4 py-4">
          <div className=" col-span-1 text-center font-sans py-2 flex justify-center items-center flex-col">
            <div className="py-3 text-2xl font-bold">Total Active Members</div>
            <div ref={ref}>
              <animated.h1 className="px-4 py-4  md:text-8xl text-6xl font-extrabold shadow-md font-serif  border rounded-full bg-blue-400 border-gray-500 my-2 hover:scale-105 duration-200 cursor-pointer text-white">
                {number.interpolate((val) => Math.floor(val))}
              </animated.h1>
            </div>
          </div>

          <div className="col-span-3 bg-blue-200">
            <div>
              <h4 className="w-full px-2 py-2 md:text-3xl text-2xl font-bold font-sans">
                Our Mission
              </h4>
              <p className="px-5 font-sans">
                Make disciples of Jesus Christ who live as His loving witness
                and proclaim to all people the everlasting gospel of the Three
                Angels' Messages in preparation for His soon return (Matt
                28:18-20,Acts 1:8,Rev 14:6-12)
              </p>
            </div>
            <div>
              <h4 className="w-full px-2 py-2 md:text-3xl text-2xl font-sans font-bold">
                Visit Us
              </h4>
              <p className=" px-5 font-sans">
                We invite you to experience the warmth of our community and the
                transformative power of faith. we Found at{" "}
                <b>Common Room Block 6 side A Second Floor</b>
              </p>
            </div>
          </div>
        </div>
        <h4 className="w-full px-4  md:text-3xl text-2xl text-center font-sans font-extrabold">
         Calvary Choir
        </h4>

        <div className="w-full my-3 px-5 py-4 grid md:grid-cols-2 grid-cols-1 bg-gray-50 font-serif">
          <div className="w-full md:px-5">
            <img src={choir} alt="" className="py-5 rounded-md" />
          </div>
          <div className="w-full flex justify-center py-4 px-2 font-sans">
            We are a passionate and harmonious community of singers dedicated to
            creating beautiful music together. Our choir embraces diversity,
            uniting voices from all walks of life to form a tapestry of sound
            that uplifts and inspires.
            <br />
            Whether you're a seasoned vocalist or someone who's always wanted to
            explore the joy of singing, we welcome you to join us. Come
            experience the magic of music-making with our choir and become part
            of something truly special.
          </div>
        </div>
      </div>

      <div className="w-full  bg-gray-700 text-white font-sans">
        <div
          className="flex justify-between  items-start gap-10 flex-wrap
        max-lg:flex-col"
        >
          <div className="flex  items-center justify-center gap-3 mt-2 px-2">
            <a
              href="https://www.youtube.com/channel/UCPM1SzyxuTq5vf95ngqgWuA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} size="2x" color="white" />
            </a>

            <a
              href="https://www.instagram.com/tucasa_cive/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" color="white" />
            </a>

            <a
              href="https://x.com/Tucasa_Cive?t=gJjILYd0KIwd5z-w3_7GoQ&s=09"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faXTwitter} size="2x" color="white" />
            </a>
          </div>
          <div className="flex flex-1 justify-around lg:gap-5 gap-2 flex-wrap ">
            <div>
              <h4 className="text-white text-2xl leading-normal font-medium">
                Related Links
              </h4>
              <ul style={{ textDecoration: "none" }}>
                <li className="mt-1 text-white hover:text-slate-gray">
                  <a
                    href="https://www.adventist.org"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Adventist Website
                  </a>
                </li>
                <li className="mt-1  hover:text-slate-gray">
                  <a
                    href="https://www.stuadventist.or.tz"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    STU Website
                  </a>
                </li>
                <li className="mt-1 text-white-400 ">
                  <a
                    href="https://www.ctfadventist.org"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    CTF Website
                  </a>
                </li>
                <li className="mt-1 text-white-400 hover:text-slate-gray">
                  <a
                    href="https://www.gcyouthministries.org/events-and-projects/public-campus-ministries/.or.tz"
                    className="text-white"
                    style={{ textDecoration: "none" }}
                  >
                    Adventist Youth PCM Website
                  </a>
                </li>
                <li className="mt-1 text-white-400 hover:text-slate-gray">
                  <a
                    href="https://www.udom.ac.tz"
                    className="text-white hover:text-slate-white"
                    style={{ textDecoration: "none" }}
                  >
                    University of Dodoma
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h4 className="text-white text-2xl leading-normal font-medium">
                Get in touch
              </h4>
              <ul style={{ textDecoration: "none" }}>
                <li className="mt-1 text-white cursor-pointer hover:text-slate-gray">
                  Phone(+255)682 827 536
                </li>
                <li className="mt-1  hover:text-slate-gray">
                  Block 6 side A second Floor
                </li>
                <li className="mt-1 text-white-400 ">info@TucasaCive.com</li>
              </ul>
            </div>
            <div>
              <h4 className="text-white text-2xl leading-normal font-medium">
                Help
              </h4>
              <ul style={{ textDecoration: "none" }}>
                <li className="mt-2 text-white cursor-pointer hover:text-slate-gray">
                  About us
                </li>
                <li className="mt-2  hover:text-slate-gray">Privacy Option</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex  justify-between  text-white mt-24 max-sm:flex-col  max-sm:items-center">
            <div className="flex flex-col justify-between  items-start px-2 max-md:items-center cursor-pointer">
              <p>Copyright &copy; 2024 Tucasa Cive</p>
              <p className="text-xs text-slate-400">developed by Laizer & Mayaya J </p>
            </div>
          </div>
      </div>
    </div>
  );
};
export default About;


import React from 'react'
import BlogHeader from './BlogsComponents/BlogHeader'
import BlogSection from './BlogsComponents/BlogSection'
import BlogFooter from './BlogsComponents/BlogFooter'

const Blogs = () => {
  return (
    <div className='w-full max-w-[1140px] mx-auto h-screen'>
       <BlogHeader/>
       <BlogSection/>
       <div className='padding-x padding-t'>
       <BlogFooter/>
       </div>
       
    </div>
  )
}

export default Blogs
import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Outlet } from "react-router-dom";
import "./Style.css";

import Register from "./pages/Register/Register";
import Login from "./pages/Login/Login";
import News from "./pages/News/News";
import About from "./pages/About/About";
import Home from "./pages/Home/Home";
import Single from "./pages/SinglePage/Single";
import Leader from "./pages/Leaders/Leader";
import Blogs from "./pages/Blogs";

import Navbar from "./components/NavBar/Navbar";
//import Footer from "./components/Footer/Footer";
import OnBoarding from "./pages/Dashboard/OnBoarding";
import Dashboard from "./pages/Dashboard/Dashboard";
import ViewMembers from "./pages/Dashboard/members/ViewMembers";
import NewMember from "./pages/Dashboard/members/NewMember";
import Archives from "./pages/Dashboard/archives/Archives";
import UploadArchive from "./pages/Dashboard/archives/UploadArchive";
import ViewSpiritualRecords from "./pages/Dashboard/spiritual/ViewSpiritualRecords";
import NewSpiritualRecords from "./pages/Dashboard/spiritual/NewSpiritualRecords";
import { AuthProvider } from "./utils/AuthContext";
import ViewMember from "./pages/Dashboard/members/ViewMember";
import Archive from "./pages/Dashboard/archives/Archive";
import Announcements from "./pages/Dashboard/announcements/Announcements";
import NewAnnouncement from "./pages/Dashboard/announcements/NewAnnouncement";
import Announcement from "./pages/Dashboard/announcements/Announcement";
import CurrentContributions from "./pages/Dashboard/contributions/CurrentContributions";
import NewContribution from "./pages/Dashboard/contributions/NewContribution";
import ViewContribution from "./pages/Dashboard/contributions/ViewContribution";
import RecordContribution from "./pages/Dashboard/contributions/RecordContribution";
import ContributionAnalysis from "./pages/Dashboard/contributions/ContributionAnalysis";
import ContributionMemberAnalysis from "./pages/Dashboard/contributions/ContributionMemberAnalysis";
import ContributionMemberHistory from "./pages/Dashboard/contributions/ContributionMemberHistory";
import EditAnnouncement from "./pages/Dashboard/announcements/EditAnnouncement";
import CurrentLeaders from "./pages/Dashboard/leadership/CurrentLeaders";
import LeadershipTransition from "./pages/Dashboard/leadership/LeadershipTransition";
import Profile from "./pages/Dashboard/Profile";
import NewWeeklySpiritualRecords from "./pages/Dashboard/spiritual/NewWeeklySpiritualRecords";
import Blog from "./pages/Blog";

const Layout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
    </>
  );
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/about-us",
        element: <About />,
      },
      {
        path: "/leader",
        element: <Leader />,
      },
      {
        path: "/news",
        element: <News />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/blog/:blogId",
        element: <Blog />,
      },
      {
        path: "/news/:newsId",
        element: <Single />,
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/dashboard",
    element: <OnBoarding />,
    children: [
      { path: "index", element: <Dashboard /> },
      { path: "profile", element: <Profile /> },
      {
        path: "members",
        element: <Outlet />,
        children: [
          {
            path: "view",
            element: (
              <ViewMembers
                to={`/dashboard/members/view`}
                title={"View Members"}
                text={"More"}
              />
            ),
          },
          {
            path: "view/:memberId",
            element: <ViewMember />,
          },
          {
            path: "new",
            element: <NewMember />,
          },
        ],
      },
      {
        path: "spiritual",
        element: <Outlet />,
        children: [
          {
            path: "view",
            element: <ViewSpiritualRecords />,
          },
          {
            path: "new",
            element: <NewSpiritualRecords />,
          },
          {
            path: "weekly",
            element: <NewWeeklySpiritualRecords />,
          },
        ],
      },
      {
        path: "announcements",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Announcements />,
          },
          {
            path: "new",
            element: <NewAnnouncement />,
          },
          {
            path: ":announcementId",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <Announcement />,
              },
              {
                path: "edit",
                element: <EditAnnouncement />,
              },
            ],
          },
        ],
      },
      {
        path: "Contributions",
        element: <Outlet />,
        children: [
          {
            path: "current",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <CurrentContributions />,
              },
              {
                path: "new",
                element: <NewContribution />,
              },
            ],
          },
          {
            path: ":contributionId",
            element: <ViewContribution />,
          },
          {
            path: "record",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: (
                  <ViewMembers
                    to={`/dashboard/contributions/record`}
                    title={"Select Member"}
                    text={"Record"}
                  />
                ),
              },
              {
                path: ":memberId",
                element: <RecordContribution />,
              },
            ],
          },
          {
            path: "analysis",
            element: <Outlet />,
            children: [
              {
                path: "",
                element: <ContributionAnalysis />,
              },
              {
                path: "member",
                element: (
                  <ViewMembers
                    title={"Select Member"}
                    to={"/dashboard/contributions/analysis/member"}
                    text={"View"}
                  />
                ),
              },
              {
                path: "member/:memberId",
                element: <ContributionMemberAnalysis />,
              },
              {
                path: "member/:memberId/history",
                element: <ContributionMemberHistory />,
              },
            ],
          },
        ],
      },
      {
        path: "archives",
        element: <Outlet />,
        children: [
          { path: "", element: <Archives /> },
          { path: "upload", element: <UploadArchive /> },
          { path: ":archiveId", element: <Archive /> },
        ],
      },
      {
        path: "leadership",
        element: <Outlet />,
        children: [
          {
            path: "current",
            element: <CurrentLeaders type={"current"} />,
          },
          {
            path: "transition",
            element: <LeadershipTransition />,
          },
          {
            path: "History",
            element: <CurrentLeaders type={"history"} />,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <h1>404</h1>,
  },
]);

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </div>
  );
}

export default App;

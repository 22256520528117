import React, { useState } from "react";
import { Link } from "react-router-dom";
import image2 from "./../../Images/IMG-20231103-WA0031.jpg";
import image3 from "./../../Images/tucasa_cive-20240304-0001.jpg";
import image4 from "./../../Images/tucasa_cive-20240304-0003.jpg";
import YouTube from "react-youtube";

import inspector from "./../Leaders/Leader/fidelis.jpg";

import projector from "./../Leaders/Leader/mtende.jpg";





const items = [
  {
    title:
      " Nayo wafunzeni vijana wenu kwa kuyazungumza uketipo katika nyumba yako na utembeapo njiani,na ulalapo na uondokapo",
    author: "Evans Mtende",
    image: projector,
  },
  {
    title:
      "Daniel 3:18 'Bali kama si Hivyo,ujue, ee mfalme yakuwa sisi hatukubali kuitumikia miungu yako, wala kuisujudia hiyo sanamu ya Dhahabu uliyoisimamisha ",
    author: "Fidelis Kagashe",
    image: inspector,
  },
  {
    title:
      "Je!, Mkushi aweza kuibadili ngozi yake,au chui madoa yake? kama aweza,ndipo na ninyi mwaweza kutenda mema,ninyi mliozoea kutenda mabaya- Yeremia 13:23",
    author: "Fidelis Kagashe",
    image: inspector,
  },
  {
    title:
      "Bwana ni mwema  Vijana wenzangu na wapendwa katika Bwana  sisi ni vijana ambao jamii inatambua tunamtumikia Mungu lakini tumekuwa tatizo na Mwiba kwa jamii inayotuzunguka ",
    author: "Evance Mtende",
    image: projector,
  },
  {
    title:
      "Mkushi aweza kuibadili ngozi yake,au chui madoa yake? kama aweza,ndipo na ninyi mwaweza kutenda mema,ninyi mliozoea kutenda mabaya- Yeremia 13:23",
    author: "Fidelis Kagashe",
    image: inspector,
  },
  {
    title:
      " Nayo wafunzeni vijana wenu kwa kuyazungumza uketipo katika nyumba yako na utembeapo njiani,na ulalapo na uondokapo",
    author: "Evans Mtende",
    image: projector,
  },
  {
    title:
      "Daniel 3:18 'Bali kama si Hivyo,ujue, ee mfalme yakuwa sisi hatukubali kuitumikia miungu yako, wala kuisujudia hiyo sanamu ya Dhahabu uliyoisimamisha ",
    author: "Fidelis Kagashe",
    image: inspector,
  },
  {
    title:
      "Daniel 3:18 'Bali kama si Hivyo,ujue, ee mfalme yakuwa sisi hatukubali kuitumikia miungu yako, wala kuisujudia hiyo sanamu ya Dhahabu uliyoisimamisha ",
    author: "Fidelis Kagashe",
    image: inspector,
  },
  {
    title:
      "Je!, Mkushi aweza kuibadili ngozi yake,au chui madoa yake? kama aweza,ndipo na ninyi mwaweza kutenda mema,ninyi mliozoea kutenda mabaya- Yeremia 13:23",
    author: "Fidelis Kagashe",
    image: inspector,
  },
  {
    title:
      "Je!, Mkushi aweza kuibadili ngozi yake,au chui madoa yake? kama aweza,ndipo na ninyi mwaweza kutenda mema,ninyi mliozoea kutenda mabaya- Yeremia 13:23",
    author: "Fidelis Kagashe",
    image: inspector,
  },
];

const BlogSection = () => {

  const videoId = 'https://www.youtube.com/watch?v=THGV53Whwu8&pp=ygUXY2FsdmFyeSBjaG9pciB1ZG9tIHdlc3Q%3D';
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const nextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleItems = items.slice(startIndex, endIndex);

  return (
    <div className="grid md:grid-cols-4 grid-cols-1 border gap-4 font-sans">
      <div className="col-span-3">
        <h4 className="px-4 text-2xl  font-bold">Documentary</h4>
        <div className="w-full ">
          {visibleItems.map((item, index) => (
            <div key={index} className="border px-4 py-4">
              <div className="font-sans">{item.title}</div>

              <div className="flex md:flex-row  flex-col gap-2 px-4">
                <img
                  src={item.image}
                  className="h-10 w-10 rounded-full"
                  alt={item.title}
                />
                <p>By: {item.author}</p>
              </div>
              <button className="px-4  py-2 bg-primary-color rounded-md my-1 shadow-md hover:scale-105 duration-300 ">
                <Link
                  to={`/blog/${index}`}
                  className="no-underline text-white "
                >
                  Read More
                </Link>
              </button>
            </div>
          ))}
        </div>
        <div className="w-full flex justify-center py-4">
          <div className="flex">
            <button
              className="flex items-center justify-center px-3 h-8 me-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={prevPage}
              disabled={currentPage === 1}
            >
              <svg
                className="w-3.5 h-3.5 me-2 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 5H1m0 0 4 4M1 5l4-4"
                />
              </svg>
              Previous
            </button>
            <button
              className="flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              onClick={nextPage}
              disabled={currentPage === totalPages}
            >
              Next
              <svg
                className="w-3.5 h-3.5 ms-2 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 5h12m0 0L9 1m4 4L9 9"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className="col-span-1">
      
      </div>
    </div>
  );
};

export default BlogSection;
